import React from 'react';
import Layout from '../components/Layout';
import Title from '../components/Title/Title';
import DynamicContent from '../components/DynamicContent/DynamicContent';
import { TwoColumns, Column } from '../components/TwoColumns/TwoColumns';
import Image from '../components/Image/Image';
import Banner from '../components/Banner/Banner';
import ExitLink from '../components/ExitLink/ExitLink';
import References from '../components/References/References';
import CtaDescList from '../components/CtaDescList/CtaDescList';
import Footnotes from '../components/Footnotes/Footnotes';

import PatientIcon from '../images/about/patientIcon.inline.svg';
import TwentyPercentIcon from '../images/about/twentyPercentIcon.inline.svg';
import FiftyPercentIcon from '../images/about/fiftyPercentIcon.inline.svg';
import TwentyMonthsIcon from '../images/about/twentyMonthsIcon.png';

import { GtagHead } from '../components/GTAG';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>About Recurrent or Metastatic Nasopharyngeal Cancer R/M NPC</title>
      <meta name='description'
        content='Learn more about R/M NPC cancer, its definition, and the challenges it presents.'/>
    </>
  )
}

export default function About() {
  const pageReferences = [
    <span>LOQTORZI<sup>®</sup>. Prescribing Information. Coherus BioSciences, Inc.</span>,
    <span>Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) for Head and Neck Cancers (v2.2023). © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed September 28, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their applications or use in any way.</span>,
    <span>Zhang L, Huang Y, Hong S, et al. Gemcitabine plus cisplatin versus fluorouracil plus cisplatin in recurrent or metastatic nasopharyngeal carcinoma: a multicentre, randomised, open-label, phase 3 trial. <em>Lancet</em>. 2016;388(10054):1883-1892. doi:10.1016/S0140-6736(16)31388-5 </span>,
    <span>Kuo D-Y, Chang M-H, Wang S-Y, Hsieh P-Y, Shueng P-W. Unusual axillary metastasis of recurrent nasopharyngeal cancer. <em>Medicine (Baltimore)</em>. 2017;96(20):e6854. doi:10.1097/MD.0000000000006854</span>,
    <span>ClinicalTrials.gov. 55 studies found for: recurrent, metastatic | nasopharyngeal carcinoma. Accessed September 28, 2023. <ExitLink className='no-underline' url='https://clinicaltrials.gov/ct2/results?term=Recurrent%2C+Metastatic&cond=Nasopharyngeal+Carcinoma&age_v=&gndr=&type=&rslt=&phase=2&Search=Apply'>https://clinicaltrials.gov/ct2/results?term=Recurrent%2C+Metastatic&cond=Nasopharyngeal+Carcinoma&age_v=&gndr=&type=&rslt=&phase=2&Search=Apply</ExitLink></span>
  ];

  const ctaDescriptionListData = [
    {
      cta: {
        text: <>LOQTORZI<sup>®</sup> at a glance</>,
        url:'/loqtorzi-at-a-glance/',
        title: <>Access to key information about LOQTORZI<sup>®</sup></>
      },
      text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
        to help you <br className='desktop:hidden' />support your patients.</div>
    }, {
      cta: {
        text: <span>Interactive <br/>
          Prescribing Information </span>,
        url:'/loqtorzi-prescribing-information/',
        title: <>Review the details of LOQTORZI<sup>®</sup> trial data</>
      },
      text: <div>Explore LOQTORZI<sup>®</sup> data
        <br className='desktop:hidden' /> in the interactive
        <br className='desktop:hidden' /> Prescribing Information.</div>
    },
    {
      cta: {
        text: 'Get Updates',
        url: '/get-updates/',
        title: <>Receive the latest information about LOQTORZI<sup>®</sup></>
      },
      text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
    }
  ];

  return (
    <Layout>
      <Title pageName={'ABOUT'}>
        What is <br className='tablet:hidden' />
        R/M NPC?
      </Title>
      <GlobalWrapper className={'add-px pb-[2rem] tablet:pb-[3rem]'} showBorderLeft={true}>
        <div className='paragraph'>R/M NPC is often considered within the context of head and neck cancers because of its location in the nasopharynx. But R/M NPC is a distinct cancer with poor prognosis and limited treatment options.<sup>2,3</sup></div>
      </GlobalWrapper>
      <DynamicContent header={<h2>Challenges with R/M NPC</h2>}>
        <TwoColumns className='!my-0 !py-0'>
          <Column className='border-none mb-[3rem] large:mb-0'>
            <Image>
              <div className='flex items-end tablet:min-h-[12rem] m-auto mb-[1rem]'>
                <PatientIcon />
              </div>
              <p className='text-center'>Patients have historically <br/>had limited treatment options <br/>(chemotherapy and radiation)<sup>2</sup></p>
            </Image>
          </Column>
          <Column className='border-none mb-[3rem] large:mb-0'>
            <Image>
              <div className='flex items-end m-auto mb-[1rem] tablet:min-h-[12rem]'>
                <TwentyPercentIcon />
              </div>
              <p className='text-center'>Only 20% of patients experience<br />a 1-year PFS<sup>3</sup></p>
            </Image>
          </Column>
        </TwoColumns>
        <TwoColumns className='!my-0 !py-0'>
          <Column className='border-none mb-[3rem] large:mb-0'>
            <Image>
              <div className='flex items-end m-auto mb-[1rem] tablet:min-h-[14.5rem]'>
                <FiftyPercentIcon />
              </div>
              <p className='text-center'>Up to 50% of patients developed<br /> distant metastasis during their<br /> disease course<sup>4</sup></p>
            </Image>
          </Column>
          <Column className='border-none mb-[3rem] large:mb-0'>
            <Image>
              <div className='flex items-end m-auto mb-[1rem] tablet:min-h-[14.5rem]'>
                <img src={ TwentyMonthsIcon } className='w-1/2 h-auto m-auto' alt=""/>
              </div>
              <p className='mb-0 text-center'>A low median overall survival<br /> (OS) of 20 months<sup>3</sup></p>
            </Image>
          </Column>
        </TwoColumns>
        <Banner className={'mt-[3.4rem]'}>
          <span>
            LOQTORZI<sup>®</sup>: first and only treatment with a successful registrational trial in R/M NPC that has led to FDA approval<sup>5</sup>
          </span>
        </Banner>
        <div className='wrapperContent !pb-0'>
          <Footnotes className={'mt-[1rem]'} items={[
            {
              bullet: '',
              text: <span>R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
            }
          ]} />
        </div>

      </DynamicContent>

      <References items={pageReferences} border={true}></References>

      <CtaDescList content={ctaDescriptionListData} />

    </Layout>
  );
}